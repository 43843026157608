var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"w-full absolute block z-30"},[_c('Header')],1),_vm._m(0),_vm._m(1),_c('div',{staticClass:"relative"},[_c('img',{staticClass:"absolute inset-0 w-full h-full object-cover",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/signature-bg.png"),"alt":"Background Image"}}),_c('div',{staticClass:"relative flex py-4 mobileL:py-0 text-green-10"},[_c('div',{staticClass:"flex ml-auto items-center gap-2 md:gap-8 2xl:gap-12 py-2 mobileL:py-24 lg:py-28 2xl:py-36 z-10 mr-10 pl-3 md:mr-12 lg:mr-32 2xl:mr-44"},[_c('div',{staticClass:"font-montserrat gap-2 mobileL:gap-0 -mb-8 md:-mb-2 lg:-mb-6 2xl:mb-4 leading-none text-right pb-8"},[_vm._m(2),_vm._m(3),_c('button',{staticClass:"block mobileL:hidden rounded-full bg-green-10 font-century ml-6 text-white-1 mobileL:mt-4 py-3 px-6 text-sm font-semibold",on:{"click":function($event){return _vm.$router.push('/blog/our-signature-wax')}}},[_vm._v(" Read More ")])]),_c('div',{staticClass:"flex-1 font-mermaid mobileL:text-left font-semibold text-base mobileL:text-lg xl:text-2xl 2xl:text-2xl leading-none"},[_vm._m(4),_c('button',{staticClass:"hidden mobileL:block rounded-full bg-green-10 font-century text-white-1 mobileL:mt-4 py-2 px-6",on:{"click":function($event){return _vm.$router.push('/blog/our-signature-wax')}}},[_vm._v(" Read More ")])])]),_vm._m(5)])]),_c('div',{staticClass:"w-full absolute block z-20"},[_c('Footer')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-top lbplus-blogs-banner-container w-full z-25"},[_c('div',{staticClass:"relative h-full w-full flex justify-center items-end flex-col"},[_c('img',{staticClass:"absolute top-0 left-0 h-full object-cover z-5 md:ml-0 lg:ml-20 2xl:ml-16 z-15",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/lbplus-blogs-banner.png"),"alt":"Blogs Banner"}}),_c('div',{staticClass:"flex flex-col ml-28 items-start mobileL:item-center z-10 max-w-sm md:max-w-md lg:max-w-md 2xl:max-w-xl p-0 mobileL:p-4 text-left mobileL:-mt-12 md:-mr-12 lg:mr-32 2xl:mr-52 md:gap-8 2xl:gap-12 leading-snug"},[_c('img',{staticClass:"hidden mobileL:block w-6 mobileL:w-16 xl:w-20 2xl:w-24",attrs:{"src":require("../../../assets/images/home-page/flower.png"),"alt":"Flower"}}),_c('h1',{staticClass:"lbplus-blogs-banner-title mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1"},[_vm._v(" Blogs ")]),_c('p',{staticClass:"font-montserrat text-sm sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl md:pr-32 lg:pr-24 pr-4 text-white-1"},[_vm._v(" Find beauty inspiration, the latest Lare Bare Plus news, recommendations and more! ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex items-center z-20"},[_c('img',{staticClass:"w-full h-36 mobileL:h-full object-cover",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/soon-banner.png"),"alt":"Background Image"}}),_c('span',{staticClass:"absolute ml-8 md:ml-12 lg:ml-24 xl:ml-36 lbplus-coming-soon-title text-3xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl"},[_vm._v(" Coming Soon! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"mb-3 mobileL:mb-4 text-xl font-semibold mobileL:text-3xl lg:text-5xl 2xl:text-6xl"},[_vm._v("Know what is "),_c('br'),_vm._v("our secret!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-xs mobileL:text-xl lg:text-3xl 2xl:text-5xl mb-2"},[_vm._v("#LayBarePlus Ph"),_c('br'),_vm._v(" #Waxing #Philippines"),_c('br'),_vm._v("#LayBarePlus")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"text-4xl mobileL:text-7xl lg:text-9xl 2xl:text-10xl mobileL:mb-4"},[_vm._v("Our "),_c('br'),_vm._v("signature "),_c('br'),_vm._v("wax")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"z-5"},[_c('img',{staticClass:"lg:w-44 2xl:w-52 mobileL:w-28 w-12 absolute left-0 bottom-0",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/pandan.png"),"alt":""}}),_c('img',{staticClass:"lg:w-32 2xl:w-36 mobileL:w-20 w-8 -left-2 top-20 mobileL:left-0 lg:top-48 absolute md:top-36 2xl:top-60",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/watermelon.png"),"alt":""}}),_c('img',{staticClass:"lg:w-28 2xl:w-36 mobileL:w-20 w-8 -left-6 top-10 lg:left-20 lg:top-20 absolute md:-left-16 md:top-20 2xl:left-8",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/watermelon-2.png"),"alt":""}}),_c('img',{staticClass:"lg:w-24 2xl:w-28 mobileL:w-16 w-6 left-4 top-6 lg:left-48 md:top-36 absolute md:left-24 2xl:left-40 2xl:top-40",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/strawberry.png"),"alt":""}}),_c('img',{staticClass:"lg:w-64 2xl:w-100 mobileL:w-40 w-12 lg:left-40 absolute -right-2 bottom-1 md:left-12 md:top-52 2xl:left-52 2xl:top-36",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/cucumber cocoa.png"),"alt":""}}),_c('img',{staticClass:"lg:w-16 2xl:w-20 mobileL:w-10 w-3 absolute lg:-left-8 lg:bottom-44 bottom-12 left-0 mobileL:-left-4 2xl:-left-10 md:bottom-28 2xl:bottom-52 transform rotate-90",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/cucumber.png"),"alt":""}}),_c('img',{staticClass:"lg:w-16 2xl:w-20 mobileL:w-10 w-3 absolute left-3 mobileL:left-8 bottom-10 lg:left-10 lg:bottom-40 2xl:left-12 md:bottom-24 2xl:bottom-44",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/cucumber.png"),"alt":""}}),_c('img',{staticClass:"lg:w-16 2xl:w-20 mobileL:w-10 w-3 absolute bottom-7 left-9 lg:left-28 lg:bottom-24 2xl:left-40 md:left-24 md:bottom-12 2xl:bottom-28",attrs:{"src":require("../../../assets/images/home-page/lbplus-blogs/cucumber.png"),"alt":""}})])
}]

export { render, staticRenderFns }