<template>
    <div class="relative">
        <div class="w-full absolute block z-30">
            <Header/>
        </div>
        <div class="space-top lbplus-blogs-banner-container w-full z-25">
            <div class="relative h-full w-full flex justify-center items-end flex-col">
                <img class="absolute top-0 left-0 h-full object-cover z-5 md:ml-0 lg:ml-20 2xl:ml-16 z-15" src="../../../assets/images/home-page/lbplus-blogs/lbplus-blogs-banner.png" alt="Blogs Banner">
                <div class="flex flex-col ml-28 items-start mobileL:item-center z-10 max-w-sm md:max-w-md lg:max-w-md 2xl:max-w-xl p-0 mobileL:p-4 text-left mobileL:-mt-12 
                    md:-mr-12 lg:mr-32 2xl:mr-52 md:gap-8 2xl:gap-12 leading-snug">
                    <img class="hidden mobileL:block w-6 mobileL:w-16 xl:w-20 2xl:w-24" src="../../../assets/images/home-page/flower.png" alt="Flower">
                    <h1 class="lbplus-blogs-banner-title mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        Blogs
                    </h1>
                    <p class="font-montserrat text-sm sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl md:pr-32 lg:pr-24 pr-4 text-white-1">
                        Find beauty inspiration, the latest Lare Bare Plus news, 
                        recommendations and more!
                    </p>
                </div>
            </div>
        </div>
        <div class="relative flex items-center z-20">
            <img class="w-full h-36 mobileL:h-full object-cover" src="../../../assets/images/home-page/lbplus-blogs/soon-banner.png" alt="Background Image">
            <span class="absolute ml-8 md:ml-12 lg:ml-24 xl:ml-36 lbplus-coming-soon-title text-3xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl">
                Coming Soon!
            </span>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/lbplus-blogs/signature-bg.png" alt="Background Image">
            <div class="relative flex py-4 mobileL:py-0 text-green-10 ">
                <div class="flex ml-auto items-center gap-2 md:gap-8 2xl:gap-12 py-2 mobileL:py-24 lg:py-28 2xl:py-36 z-10 mr-10 pl-3 md:mr-12 lg:mr-32 2xl:mr-44">
                    <div class="font-montserrat gap-2 mobileL:gap-0 -mb-8 md:-mb-2 lg:-mb-6 2xl:mb-4 leading-none text-right pb-8">
                        <h4 class="mb-3 mobileL:mb-4 text-xl font-semibold mobileL:text-3xl lg:text-5xl 2xl:text-6xl">Know what is <br>our secret!</h4>
                        <p class="text-xs mobileL:text-xl lg:text-3xl 2xl:text-5xl mb-2">#LayBarePlus Ph<br> #Waxing #Philippines<br>#LayBarePlus</p>
                        <button class="block mobileL:hidden rounded-full bg-green-10 font-century ml-6 text-white-1 mobileL:mt-4 py-3 px-6 text-sm font-semibold" @click="$router.push('/blog/our-signature-wax')">
                            Read More
                        </button>
                    </div>
                    <div class="flex-1 font-mermaid mobileL:text-left font-semibold text-base mobileL:text-lg xl:text-2xl 2xl:text-2xl leading-none">
                        <h4 class="text-4xl mobileL:text-7xl lg:text-9xl 2xl:text-10xl mobileL:mb-4">Our <br>signature <br>wax</h4>
                        <button class="hidden mobileL:block rounded-full bg-green-10 font-century text-white-1 mobileL:mt-4 py-2 px-6" @click="$router.push('/blog/our-signature-wax')">
                            Read More
                        </button>
                    </div>
                </div>
                <div class="z-5">
                    <img class="lg:w-44 2xl:w-52 mobileL:w-28 w-12 absolute left-0 bottom-0" 
                    src="../../../assets/images/home-page/lbplus-blogs/pandan.png" alt="">
                    <img class="lg:w-32 2xl:w-36 mobileL:w-20 w-8 -left-2 top-20 mobileL:left-0 lg:top-48 absolute md:top-36 2xl:top-60" 
                    src="../../../assets/images/home-page/lbplus-blogs/watermelon.png" alt="">
                    <img class="lg:w-28 2xl:w-36 mobileL:w-20 w-8 -left-6 top-10 lg:left-20 lg:top-20 absolute md:-left-16 md:top-20 2xl:left-8" 
                    src="../../../assets/images/home-page/lbplus-blogs/watermelon-2.png" alt="">
                    <img class="lg:w-24 2xl:w-28 mobileL:w-16 w-6 left-4 top-6 lg:left-48 md:top-36 absolute md:left-24 2xl:left-40 2xl:top-40" 
                    src="../../../assets/images/home-page/lbplus-blogs/strawberry.png" alt="">
                    <img class="lg:w-64 2xl:w-100 mobileL:w-40 w-12 lg:left-40 absolute -right-2 bottom-1 md:left-12 md:top-52 2xl:left-52 2xl:top-36" 
                    src="../../../assets/images/home-page/lbplus-blogs/cucumber cocoa.png" alt="">
                    <img class="lg:w-16 2xl:w-20 mobileL:w-10 w-3 absolute lg:-left-8 lg:bottom-44 bottom-12 left-0 mobileL:-left-4 2xl:-left-10 md:bottom-28 2xl:bottom-52 transform rotate-90" 
                    src="../../../assets/images/home-page/lbplus-blogs/cucumber.png" alt="">
                    <img class="lg:w-16 2xl:w-20 mobileL:w-10 w-3 absolute left-3 mobileL:left-8 bottom-10 lg:left-10 lg:bottom-40 2xl:left-12 md:bottom-24 2xl:bottom-44" 
                    src="../../../assets/images/home-page/lbplus-blogs/cucumber.png" alt="">
                    <img class="lg:w-16 2xl:w-20 mobileL:w-10 w-3 absolute bottom-7 left-9 lg:left-28 lg:bottom-24 2xl:left-40 md:left-24 md:bottom-12 2xl:bottom-28" 
                    src="../../../assets/images/home-page/lbplus-blogs/cucumber.png" alt="">
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/laybareplus/blogs')
    },
}
</script>

<style>
.lbplus-blogs-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #488c7d 0%, #2B6D5F 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-blogs-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-blogs-banner-title {
    color: #FFF;
    text-align: center;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-blogs-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-blogs-banner-container{
    height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.lbplus-coming-soon-title {
    color: #FFF;
    text-shadow: 0px 3px 0px #AF7462;
    font-family: Mermaid;
}

@media only screen and (min-width: 768px) {
    .lbplus-coming-soon-title {
        text-shadow: 0px 6px 0px #C9827C;
    }
}

@media only screen and (min-width: 992px) {
    .lbplus-coming-soon-title {
        text-shadow: 0px 8px 0px #C9827C;
    }
}

@media only screen and (min-width: 1200px) {
    .lbplus-coming-soon-title {
        text-shadow: 0px 10px 0px #C9827C;
    }
}

</style>